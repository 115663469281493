import React from 'react';
import AppRouter from './router/AppRouter';
import './App.css';

function App() {
  return(
    <AppRouter />
  )
}

export default App;
