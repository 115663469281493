import React from 'react';
import NavBar from '../elements/NavBar';
import '../styles/Home.css';

import Humans from '../img/humans.webp';

function Home() {
    return(
        <body>
            <NavBar></NavBar>
            <div className='welcomeSection'>
                <div className='welcomeTextSection'>
                    <h1 className='welcomeTitle'>Great things can be created when humans work together.</h1>
                </div>
                <img src={ Humans } alt="Humans working together" className='welcomePhoto'/>
            </div>
        </body>
    );
}

export default Home;